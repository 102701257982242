import { SubmissionError } from 'redux-form';
import {
  getAddress, getZip, getCity, getState, getCountry,
} from '../../utils/func';
import { digitOnly } from '../../utils/mask';
import { getAccessorialByDivision } from '../../services/accessorialService';
import { postPickUp } from '../../services/pickupService';
import { PaymentMethods, SamedayShipmentType } from '../../utils/enums';

const measurementLists = (measurementList, measurementType) => {
  const measurementListsToApi = measurementList.map((each) => {
    if (each.itemDangerous) {
      return {
        measurementType,
        weight: Number(each.itemWeight),
        length: Number(each.itemLength),
        width: Number(each.itemWidth),
        height: Number(each.itemHeight),
        pieces: Number(each.itemCount),
        description: each.itemDescription,
        isDangerousGoods: each.itemDangerous,
        unNumber: each.unNumber,
        primaryClass: each.primeClass,
        subsidaryClass: each.secondClass,
        packagingGroup: each.packagingGroup ? each.packagingGroup.value : null,
        isToxicByInhalation: each.toxic ? each.toxic : false,
        quantity: each.totalQuantity,
        quantityType: each.totalQuantityUnits,
        shippingName: each.shippingName,
        numOfPackagesRequiringLabels: Number(each.numberPackRequireLabel),
        isNEQ: each.neq ? each.neq : false,
        freightClass: each.itemFreightClass,
        nmfc: each.itemNMFC,
      };
    }

    return {
      measurementType,
      weight: Number(each.itemWeight),
      length: Number(each.itemLength),
      width: Number(each.itemWidth),
      height: Number(each.itemHeight),
      pieces: Number(each.itemCount),
      description: each.itemDescription,
      freightClass: each.itemFreightClass,
      nmfc: each.itemNMFC,
    };
  });

  return measurementListsToApi;
};

// eslint-disable-next-line max-len
const accessorialsLists = (accessorials) => (accessorials ?
  Object.values(accessorials).filter((acc) => acc.selected.length > 1).map((each) => {
    const {
      selected,
      value,
      currency
    } = each;
    if (value && currency) {
      return {
        accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
        code: selected[1].code,
        value: Number(value),
        currencyCode: currency,
      };
    }

    return {
      accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
      code: selected[1].code,
    };
  }) : '');

export const getAccessorials = ({
  division,
  filterForPickup,
  updateState,
  USPickup
}) => {
  getAccessorialByDivision(division, filterForPickup).then((res) => {
    if (res.status === 200) {
      if (!USPickup) {
        const resData = res.data;
        const domesticAccessorialWithoutInBond = resData.filter((item) => item.accessorialId !== 300
          || item.accessorialId !== 47 || item.code !== 'INBOND');
        updateState('accList', domesticAccessorialWithoutInBond);
      } else {
        updateState('accList', res.data);
      }
    }
  });
};

const parseAttachedDocuments = (attachedDocuments, formValueToApi) => {
  if (attachedDocuments) {
    // eslint-disable-next-line no-param-reassign
    formValueToApi.uploadFiles = [];
    Object.keys(attachedDocuments).forEach((key) => {
      formValueToApi.uploadFiles.push({
        fileName: attachedDocuments[key].name,
        fileDataAsBase64: attachedDocuments[key].file,
      });
    });
  }
};

export const submitPickup = ({
  formData,
  updateState,
  // prevStep
}) => {
  const {
    division,
    paymentMethod,
    shipperContactName,
    shipperCompanyName,
    shipperAddress,
    shipperAddressAdditional,
    shipperEmail,
    shipperPhoneNumber,
    shipperPhoneNumberExt,
    consigneeAddressAdditional,
    consigneeEmail,
    consigneePhoneNumber,
    consigneeAddress,
    consigneeContactName,
    consigneeCompanyName,
    consigneePhoneNumberExt,
    measurementType,
    measurementList,
    accessorials,
    quoteDest,
    pickupDate,
    pickupDetails,
    emergencyPhone,
    erapReference,
    erapPhone,
    referenceNumber,
    poNumber,
    specialInstructions,
    readyTime,
    closingTime,
    shipmentType,
    quoteNumber,
    billingAddressAdditional,
    billingEmail,
    billingPhoneNumber,
    billingAddress,
    billingContactName,
    billingCompanyName,
    billingPhoneNumberExt,
    gstNumber,
    attachedDocuments,
  } = formData;

  const shipperAddress1 = getAddress(shipperAddress);
  const shipperCity = getCity(shipperAddress);
  const shipperState = getState(shipperAddress);
  const shipperZip = getZip(shipperAddress);
  const shipperCountry = getCountry(shipperAddress);

  const formValueToApi = {
    isPickupWithBOL: pickupDetails.createBillOfLading,
    paymentType: paymentMethod.name.split(' ').join(''),
    language: 'EN',
    shipmentType: shipmentType || SamedayShipmentType.Regular,
    division,
    from: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: shipperEmail,
    },
    thirdPartyDetails: paymentMethod.name === PaymentMethods.ThirdParty ? {
      companyName: billingCompanyName,
      contactName: billingContactName,
      address1: getAddress(billingAddress),
      address2: billingAddressAdditional,
      city: getCity(billingAddress),
      provinceCode: getState(billingAddress),
      countryCode: getCountry(billingAddress),
      postalCode: getZip(billingAddress),
      contactPhoneNumber: digitOnly(billingPhoneNumber),
      contactPhoneNumberExt: billingPhoneNumberExt,
      contactEmail: billingEmail,
    } : undefined,
    gstNumber,
    shipmentItems: measurementLists(measurementList, measurementType.value),
    accessorials: accessorialsLists(accessorials),
    confirmationEmail: quoteDest,
    measurementType: measurementType.value,
    specialInstructions,
    isAllowAccessByTrailer: pickupDetails.accessByTrailer.value,
    pickupDetails: {
      pickupDate,
      readyTime: `${readyTime.hours.length < 2 ?
        `0${readyTime.hours}` : readyTime.hours}:${readyTime.minutes.length < 2 ?
        `0${readyTime.minutes}` : readyTime.minutes} ${readyTime.amOrPm}`,
      closingTime: `${closingTime.hours.length < 2 ?
        `0${closingTime.hours}` : closingTime.hours}:${closingTime.minutes.length < 2 ?
        `0${closingTime.minutes}` : closingTime.minutes} ${closingTime.amOrPm}`,
    },
    referenceNumber,
    poNumber,
    emergencyPhoneNumber: emergencyPhone ? digitOnly(emergencyPhone) : emergencyPhone,
    erapReferenceNumber: erapReference,
    erapPhoneNumber: erapPhone ? digitOnly(erapPhone) : erapPhone,
    spotQuoteNumber: quoteNumber,
  };

  // If createBillOfLading, add "to" attribute, to request body...
  if (pickupDetails.createBillOfLading) {
    const consigneeAddress1 = getAddress(consigneeAddress);
    const consigneeCity = getCity(consigneeAddress);
    const consigneeState = getState(consigneeAddress);
    const consigneeZip = getZip(consigneeAddress);
    const consigneeCountry = getCountry(consigneeAddress);

    formValueToApi.to = {
      companyName: consigneeCompanyName,
      contactName: consigneeContactName,
      address1: consigneeAddress1,
      address2: consigneeAddressAdditional,
      city: consigneeCity,
      provinceCode: consigneeState,
      countryCode: consigneeCountry,
      postalCode: consigneeZip,
      contactPhoneNumber: digitOnly(consigneePhoneNumber),
      contactPhoneNumberExt: consigneePhoneNumberExt,
      contactEmail: consigneeEmail,
    };
  }

  parseAttachedDocuments(attachedDocuments, formValueToApi);

  return postPickUp(formValueToApi)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        updateState('data', {
          probillNumber: res.data,
        });
        window.scrollTo(0, 0);
      }
    }).catch(() => {
      throw new SubmissionError({});
    });
};

export const submitPickupAuthenticate = ({
  formData,
  updateState,
  division,
  userId,
  userEmail,
  // customerId,
  // prevStep,
}) => {
  const {
    billTo,
    paymentMethod,
    shipperContactName,
    shipperCompanyName,
    shipperAddress,
    shipperAddressAdditional,
    shipperEmail,
    shipperPhoneNumber,
    shipperPhoneNumberExt,
    consigneeAddressAdditional,
    consigneeEmail,
    consigneePhoneNumber,
    consigneeAddress,
    consigneeContactName,
    consigneeCompanyName,
    consigneePhoneNumberExt,
    measurementType,
    measurementList,
    accessorials,
    quoteDest,
    pickupDate,
    pickupDetails,
    emergencyPhone,
    erapReference,
    erapPhone,
    referenceNumber,
    poNumber,
    specialInstructions,
    readyTime,
    closingTime,
    shipmentType,
    sendConsigneeInfo,
    quoteNumber,
    billingAddressAdditional,
    billingEmail,
    billingPhoneNumber,
    billingAddress,
    billingContactName,
    billingCompanyName,
    billingPhoneNumberExt,
    gstNumber,
    attachedDocuments,
  } = formData;

  const shipperAddress1 = getAddress(shipperAddress);
  const shipperCity = getCity(shipperAddress);
  const shipperState = getState(shipperAddress);
  const shipperZip = getZip(shipperAddress);
  const shipperCountry = getCountry(shipperAddress);

  const formValueToApi = {
    isPickupWithBOL: pickupDetails.createBillOfLading,
    accountNumber: billTo,
    customerId: 56395, // TODO: what is customer ID and why do we have it hard coded everywhere???
    userId: Number(userId),
    sendEmailToConsignee: sendConsigneeInfo,
    createByUserId: userId,
    createByUserEmail: userEmail,
    paymentType: paymentMethod.name.split(' ').join(''),
    language: 'EN',
    shipmentType: shipmentType || SamedayShipmentType.Regular,
    division,
    from: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: shipperEmail,
    },
    thirdPartyDetails: paymentMethod.name === PaymentMethods.ThirdParty ? {
      companyName: billingCompanyName,
      contactName: billingContactName,
      address1: getAddress(billingAddress),
      address2: billingAddressAdditional,
      city: getCity(billingAddress),
      provinceCode: getState(billingAddress),
      countryCode: getCountry(billingAddress),
      postalCode: getZip(billingAddress),
      contactPhoneNumber: digitOnly(billingPhoneNumber),
      contactPhoneNumberExt: billingPhoneNumberExt,
      contactEmail: billingEmail,
    } : undefined,
    gstNumber,
    shipmentItems: measurementLists(measurementList, measurementType.value),
    accessorials: accessorialsLists(accessorials),
    confirmationEmail: quoteDest,
    measurementType: measurementType.value,
    specialInstructions,
    isAllowAccessByTrailer: pickupDetails.accessByTrailer.value,
    pickupDetails: {
      pickupDate,
      readyTime: `${readyTime.hours.length < 2 ?
        `0${readyTime.hours}` : readyTime.hours}:${readyTime.minutes.length < 2 ?
        `0${readyTime.minutes}` : readyTime.minutes} ${readyTime.amOrPm}`,
      closingTime: `${closingTime.hours.length < 2 ?
        `0${closingTime.hours}` : closingTime.hours}:${closingTime.minutes.length < 2 ?
        `0${closingTime.minutes}` : closingTime.minutes} ${closingTime.amOrPm}`,
    },
    referenceNumber,
    poNumber,
    emergencyPhoneNumber: emergencyPhone ? digitOnly(emergencyPhone) : emergencyPhone,
    erapReferenceNumber: erapReference,
    erapPhoneNumber: erapPhone ? digitOnly(erapPhone) : erapPhone,
    spotQuoteNumber: quoteNumber,
  };

  // If createBillOfLading, add "to" attribute, to request body...
  if (pickupDetails.createBillOfLading) {
    const consigneeAddress1 = getAddress(consigneeAddress);
    const consigneeCity = getCity(consigneeAddress);
    const consigneeState = getState(consigneeAddress);
    const consigneeZip = getZip(consigneeAddress);
    const consigneeCountry = getCountry(consigneeAddress);

    formValueToApi.to = {
      companyName: consigneeCompanyName,
      contactName: consigneeContactName,
      address1: consigneeAddress1,
      address2: consigneeAddressAdditional,
      city: consigneeCity,
      provinceCode: consigneeState,
      countryCode: consigneeCountry,
      postalCode: consigneeZip,
      contactPhoneNumber: digitOnly(consigneePhoneNumber),
      contactPhoneNumberExt: consigneePhoneNumberExt,
      contactEmail: consigneeEmail,
    };
  }

  parseAttachedDocuments(attachedDocuments, formValueToApi);

  return postPickUp(formValueToApi)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        updateState('data', {
          probillNumber: res.data,
        });
        window.scrollTo(0, 0);
      }
    }).catch(() => {
      throw new SubmissionError({});
    });
};
