import React from 'react';
import {
  reduxForm,
  getFormValues,
  change,
  SubmissionError,
  getFormSyncErrors,
  touch,
  updateSyncErrors
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { MDBDropdown } from 'mdbreact';
import Modal from 'react-modal';
import { Beforeunload } from 'react-beforeunload';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import FormProgressBar from '../ReactComponent/FormProgressBar';
import SignupBanner from '../ReactComponent/SignupBanner';
import ArrowRightWhite from '../../assets/icons/ArrowRightWhite.svg';
import ArrowLeftOrange from '../../assets/icons/ArrowLeftOrange.svg';
import CancelWhite from '../../assets/icons/CancelWhite.svg';
import {
  measurementValidate,
  isUSZip,
  isCANPostal,
  listCompleted
} from '../../utils/validator';
import { submitPickup, getAccessorials, submitPickupAuthenticate } from './api';
import {
  Icon,
  PrimaryButton,
  SecondaryButton,
  ButtonGroup,
  FormContainer,
} from './css';
import { MDBDropdownToggle, StyledMDBDropdownMenu } from '../_styledComponents/MDBDropdownToggle';
import {
  getZip,
  isCanadianAddress,
  scrollToTop,
  openFileInNewTab,
  getCity,
  validatePostal,
  getTimeObject,
  formatDate,
  runningOnBrowser,
  scrollToRef,
  getCountry,
} from '../../utils/func';
import {
  DeliveryTypes,
  Divisions,
  PaymentMethods,
  shipmentPickupServiceTypes,
  IsYesOrNo
} from '../../utils/enums';
import { getPickupBol } from '../../services/pickupService';
import QuestionsBlock from '../ReactComponent/QuestionsBlock';
import Row from '../ReactComponent/Row';
import Column from '../ReactComponent/Column';
import { DropdownItem, DropdownItemIcon } from '../ReactComponent/SortableTable/css';
import { LastActions } from '../CreateShipment/css';
import ModalBoxStyle from '../_styledComponents/ModalBoxStyle';
import {
  Close,
  ModalBody,
  ModalTitle,
  ButtonDiv
} from '../ManagePickups/css';
import { list as unitsList } from '../ReactComponent/Measurement/Units/utils';
import { getPreferences } from '../../services/users';
import AddressPickerModal from '../ReactComponent/AddressPickerModal';

const formName = 'CreatePickupForm';
let apiReadyTime = '';

const initialValues = {
  division: Divisions.Freight.name,
  deliveryType: DeliveryTypes.Commercial,
  measurementType: unitsList[0],
  accessorials: {},
  dangerDisable: false,
  measurementList: [{}],
  pickupDetails: {
    accessByTrailer: {
      name: IsYesOrNo.Yes,
      value: true
    },
    createBillOfLading: null,
  }
};

class CreatePickupForm extends React.Component {
  constructor(props) {
    super(props);
    const { fields } = props;
    this.steps = [fields.step1Title.value,
      fields.step2Title.value,
      fields.step3Title.value,
      fields.step4Title.value,
      fields.step5Title.value,
      fields.step6Title.value];
    this.state = {
      currentStep: 0,
      data: {},
      accList: [],
      isManageContactPermission: false,
      cancelPickup: false,
      embargoErrorMessage: false
    };
    this.addressFieldsRef = React.createRef();
  }

  updatePickupDate = (readyDate, closingDate) => {
    const { dispatch } = this.props;
    const currentDate = new Date();
    const readyDateFormatted = getTimeObject(readyDate);
    const closingDateFormatted = getTimeObject(closingDate);

    dispatch(change(formName, 'pickupDate', formatDate(currentDate)));
    dispatch(change(formName, 'readyTime.hours', readyDateFormatted.hours));
    dispatch(change(formName, 'readyTime.minutes', readyDateFormatted.minutes));
    dispatch(change(formName, 'readyTime.amOrPm', readyDateFormatted.amOrPm));
    dispatch(change(formName, 'closingTime.hours', closingDateFormatted.hours));
    dispatch(change(formName, 'closingTime.minutes', closingDateFormatted.minutes));
    dispatch(change(formName, 'closingTime.amOrPm', closingDateFormatted.amOrPm));
  };

  componentDidMount() {
    const {
      dispatch,
      userId,
      userEmail,
      formData
    } = this.props;
    let divisionQueryParam;
    if (runningOnBrowser) {
      const urlParams = new URLSearchParams(window.location.search);
      divisionQueryParam = urlParams.get('division');
      if (divisionQueryParam) {
        dispatch(change(formName, 'division', divisionQueryParam));
      }
      if (urlParams.get('quoteNumber')) {
        dispatch(change(formName, 'quoteNumber', urlParams.get('quoteNumber')));
      }
      const division = divisionQueryParam || (formData && formData.division) || this.props.division || Divisions.Freight.name;
      const serviceTypeQueryParam = urlParams.get('serviceType');

      dispatch(change(
        formName, 'serviceType', serviceTypeQueryParam ||
      (division === Divisions.Sameday.name
        ? shipmentPickupServiceTypes[division][1] : shipmentPickupServiceTypes[division][2]
      )));

      const currentDate = new Date();
      const readyDate = new Date();
      const closingDate = new Date();
      readyDate.setHours(currentDate.getHours(), currentDate.getMinutes() + 15);
      closingDate.setHours(17, 0, 0, 0); // 5:00 PM...
      this.updatePickupDate(readyDate, closingDate);
      getPreferences(userId, division)
        .then((res) => {
          if (res.status === 200 && res.data) {
            apiReadyTime = res.data.readyTime;
            this.updatePickupDate(new Date(res.data.readyTime),
              res.data.closingTime !== '0001-01-01T00:00:00' ? new Date(res.data.closingTime) : closingDate);
            this.formPrefData(res.data);
            this.setState({
              ...res.data
            });
          }
        });
    }
    if (this.props.isAuthenticated) {
      dispatch(change(formName, 'quoteDest', userEmail));
      dispatch(change(formName, 'division', this.props.division));
    }
    this.setInitialPaymentMethod();
    dispatch(change(formName, 'pickupDetails.createBillOfLading', !(divisionQueryParam === Divisions.Sameday.name
      || (formData && formData.division === Divisions.Sameday.name)
      || this.props.division === Divisions.Sameday.name)));
  }

  formPrefData = (resData) => {
    const { dispatch } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const dontUsePref = !!(urlParams.get('quoteNumber') || urlParams.get('shipmentId') || urlParams.get('probillNumber'));
    return {
      ...resData,
      billTo: dontUsePref ? undefined : dispatch(change(formName, 'billTo', resData.billToAccountNumber)),
    };
  };

  getRegularScheduledPickup = (regularScheduledPickup) => {
    const { dispatch } = this.props;
    let userPrefPickup;

    if (regularScheduledPickup === IsYesOrNo.Yes) {
      userPrefPickup = {
        name: IsYesOrNo.Yes,
        value: true
      };
    }

    if (regularScheduledPickup === IsYesOrNo.No) {
      userPrefPickup = {
        name: IsYesOrNo.No,
        value: false
      };
    }

    dispatch(change(formName, 'regularScheduledPickup', userPrefPickup));
  };

  setInitialPaymentMethod = () => {
    const {
      dispatch,
      isAuthenticated
    } = this.props;
    if (isAuthenticated) {
      dispatch(change(formName, 'paymentMethod', {
        name: PaymentMethods.Prepaid,
        desc: 'PrepaidDesc'
      }));
    } else {
      dispatch(change(formName, 'paymentMethod', {
        name: PaymentMethods.CreditCard,
        desc: 'CreditCardDesc'
      }));
    }
  };

  /* eslint-disable consistent-return */
  nextStep = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      formData,
      division,
      formData: {
        measurementList,
        pickupDetails,
        shipperAddress,
        consigneeAddress
      },
      dispatch,
      formSyncErrors
    } = this.props;
    const hasMeasureMents = this.state.currentStep !== 1 ? true : !!measurementList;
    const shipperCountry = getCountry(formData.shipperAddress);
    const consigneeCountry = getCountry(formData.consigneeAddress);
    if (pickupDetails.createBillOfLading === false && isUSZip(getZip(shipperAddress)) === true) {
      this.setState({ modalUSAIsOpen: true });
      dispatch(change(formName, 'pickupDetails.createBillOfLading', true));
      return false;
    }
    if (this.props.valid && hasMeasureMents) {
      if ((this.state.currentStep === 0) && (t('EmbargoFlag') === 'ON')
        && ((formData.shipperAddress && formData.shipperAddress.includes('BC'))
          || (formData.consigneeAddress && formData.consigneeAddress.includes('BC'))
          || (formData.shipperAddress && formData.shipperAddress.includes('AB'))
          || (formData.consigneeAddress && formData.consigneeAddress.includes('AB'))
          || (formData.shipperAddress && formData.shipperAddress.includes('SK'))
          || (formData.consigneeAddress && formData.consigneeAddress.includes('SK'))
          || (formData.shipperAddress && formData.shipperAddress.includes('MB'))
          || (formData.consigneeAddress && formData.consigneeAddress.includes('MB')))) {
        if ((shipperCountry !== 'US') && (consigneeCountry !== 'US')) {
          const errorMessage = t('EmbargoErrorMessage');
          this.setState({
            modalIsOpen: true,
            errorMessage,
            embargoErrorMessage: true
          });
        }
      } else if ((this.state.currentStep === 0) && (t('EmbargoUSFlag') === 'ON')
        && (formData && formData.division === Divisions.Freight.name)) {
        if (shipperCountry === 'US' || consigneeCountry === 'US') {
          const errorMessage = t('EmbargoUSWarning');
          this.setState({
            modalIsOpen: true,
            errorMessage,
            embargoErrorMessage: true
          });
        }
      }
      if (this.state.currentStep === 0) {
        validatePostal(getZip(formData.shipperAddress), formData.division || division
          || Divisions.Freight.name).then((msg) => {
          const error = formSyncErrors;
          if (!Array.isArray(msg)) {
            error.shipperAddress = msg;
          } else {
            error.shipperAddress = undefined;
            if (!msg.find((c) =>
              c.cityName && c.cityName.toLowerCase() === getCity(formData.shipperAddress).toLowerCase()
            )) {
              this.setState({
                addressPickerModalOpen: true,
                AddressOptions: msg.filter((a) => a.cityName),
                addressPickField: 'shipperAddress'
              });
              return;
            }
          }
          if (error.shipperAddress) {
            scrollToRef(this.addressFieldsRef);
            dispatch(touch(formName, 'shipperAddress'));
            throw dispatch(updateSyncErrors(formName, error));
          }
          if (pickupDetails && pickupDetails.createBillOfLading) {
            validatePostal(getZip(formData.consigneeAddress), formData.division || division || Divisions.Freight.name).then((message) => {
              const formError = formSyncErrors;
              if (!Array.isArray(message)) {
                formError.consigneeAddress = message;
              } else {
                formError.consigneeAddress = undefined;
                if (!message.find((c) =>
                  c.cityName && c.cityName.toLowerCase() === getCity(formData.consigneeAddress).toLowerCase()
                )) {
                  this.setState({
                    addressPickerModalOpen: true,
                    AddressOptions: message.filter((a) => a.cityName),
                    addressPickField: 'consigneeAddress'
                  });
                  return;
                }
              }
              if (formError.consigneeAddress) {
                scrollToRef(this.addressFieldsRef);
                dispatch(touch(formName, 'consigneeAddress'));
                throw dispatch(updateSyncErrors(formName, formError));
              }
              this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
                setTimeout(() => scrollToTop(), 200);
              });
            });
          } else {
            this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
              setTimeout(() => scrollToTop(), 200);
            });
          }
        });
        const USShipment = (isCANPostal(getZip(shipperAddress)) && isUSZip(getZip(consigneeAddress)))
          || (isUSZip(getZip(shipperAddress)) && isCANPostal(getZip(consigneeAddress)));
        dispatch(change(formName, 'USShipment', USShipment));

        if (USShipment) {
          getAccessorials({
            division: formData.division || division || Divisions.Freight.name,
            USPickup: true,
            filterForPickup: !pickupDetails.createBillOfLading,
            updateState: this.updateState
          });
        } else {
          getAccessorials({
            division: formData.division || division || Divisions.Freight.name,
            filterForPickup: !pickupDetails.createBillOfLading,
            updateState: this.updateState
          });
        }
      }

      if (this.state.currentStep === 1 && !listCompleted(measurementList)) {
        return null;
      }
      this.scrollToTop();
      if (this.state.currentStep !== 0) {
        this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
          scrollToTop();
          setTimeout(() => scrollToTop(), 200);
        });
      }
    }
  };
  /* eslint-enable consistent-return */

  updateState = (name, value) => this.setState({ [name]: value });

  prevStep = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.scrollToTop();
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
  };

  scrollToTop = () => window.scrollTo(0, 0);

  editStepOne = (e) => {
    e.preventDefault();
    this.setState({ currentStep: 0 });
  };

  editStepTwo = (e) => {
    e.preventDefault();
    this.setState({ currentStep: 1 });
  };

  editStepThree = (e) => {
    e.preventDefault();
    this.setState({ currentStep: 2 });
  };

  editStepFour = (e) => {
    e.preventDefault();
    this.setState({ currentStep: 3 });
  };

  openBOL = () => {
    const { formData: { division } } = this.props;
    const { data: { probillNumber: { orderNumber } } } = this.state;
    getPickupBol({
      division,
      orderNumber
    })
      .then((res) => {
        if (res.status === 200) {
          openFileInNewTab({
            dataAsBase64: res.data.fileDataAsBase64,
            mimeType: res.data.mimeType
          });
        }
      });
  };

  handleSubmit = async () => {
    const {
      formData,
      isAuthenticated,
      division,
      userId,
      userEmail
    } = this.props;
    const { currentStep } = this.state;
    if (isAuthenticated) {
      await submitPickupAuthenticate({
        formData,
        division,
        userId,
        updateState: this.updateState,
        userEmail,
        prevStep: this.prevStep
      }).catch((err) => {
        throw new SubmissionError({ err });
      });
    } else {
      await submitPickup({
        formData,
        updateState: this.updateState,
        prevStep: this.prevStep
      }).catch((err) => {
        throw new SubmissionError(err);
      });
    }
    if (currentStep === 4) {
      this.scrollToTop();
      this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
        setTimeout(() => scrollToTop(), 200);
      });
    }
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
      modalUSAIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      errorMessage: '',
      modalUSAIsOpen: false,
      embargoErrorMessage: false
    });
  };

  cancelFn = () => {
    // const { data: { probillNumber: { orderNumber } } } = this.state;
    const errorMessage = t('CannotCancelMsg');
    this.setState({
      modalIsOpen: true,
      errorMessage,
      cancelPickup: true
    });
  };

  handleAddressPickSubmit = (selectedAddress) => {
    const {
      dispatch,
      formData
    } = this.props;
    const { addressPickField } = this.state;
    const newVal = formData[addressPickField].replace(getCity(formData[addressPickField]), selectedAddress.cityName);
    dispatch(change(formName, addressPickField, newVal));
    this.closeAddressModal(selectedAddress);
  };

  closeAddressModal = (selectedAddress) => {
    this.setState({
      addressPickerModalOpen: false,
      AddressOptions: []
    },
    () => {
      if (selectedAddress && selectedAddress.cityName) {
        this.nextStep();
        return null;
      }
      const {
        dispatch,
        formSyncErrors
      } = this.props;
      const { addressPickField } = this.state;
      const error = formSyncErrors;
      error[addressPickField] = t('AddressNotFound');

      return dispatch(updateSyncErrors(formName, error));
    });
  };

  openContactPermissionModal = () => {
    const { fields } = this.props;
    const errorMessage = t(fields.ManageContactPermissionWarning.value);
    this.setState({
      modalIsOpen: true,
      errorMessage,
      currentStep: 0,
      isManageContactPermission: true
    });
  };

  handleModalButtons = (event) => {
    event.preventDefault();
    const {
      target: { name }
    } = event;
    if (name === 'cancelButton') {
      this.closeModal();
    } else if (name === 'returnToStepTwo') {
      setTimeout(() => scrollToTop(), 200);
    }
    this.closeModal();
  };

  render() {
    const {
      fields,
      formData,
      valid,
      handleSubmit,
      submitSucceeded,
      dispatch,
      isAuthenticated,
      accountNumbers,
      billingAccounts = [],
      formSyncErrors
    } = this.props;
    const {
      currentStep,
      accList,
      modalIsOpen,
      modalUSAIsOpen,
      errorMessage,
      fromContactId,
      toContactId,
      isManageContactPermission,
      cancelPickup,
      embargoErrorMessage
    } = this.state;
    const LTLCustomerNumber = 'LTL: 1.866.Day.Ross (329-7677)';
    const CommerceCustomerNumber = 'Commerce: 1.877.726.3329';

    const modalTitle = () => {
      let title;
      if (embargoErrorMessage) {
        title = t('Alert');
      } else if (isManageContactPermission) {
        title = t(fields.ManageContactPermissionTitle.value);
      } else if (errorMessage && cancelPickup) {
        title = t('');
      } else {
        title = t('Error');
      }
      return title;
    };

    const getSecondaryButton = () => {
      if (embargoErrorMessage) {
        return (
          <SecondaryButton
            onClick={(e) => this.handleModalButtons(e)}
            className="active"
            name="returnToStepTwo"
            style={{ marginBottom: '20px' }}
          >
            {t('Okay')}
            <span className="icon" />
          </SecondaryButton>
        );
      }
      if (isManageContactPermission || (errorMessage && cancelPickup)) {
        return (
          <SecondaryButton
            onClick={(e) => this.closeModal(e)}
            className="active"
            name="Okay"
          >
            {t('Okay')}
            <span className="icon" />
          </SecondaryButton>
        );
      }
      return (
        <SecondaryButton
          onClick={(e) => this.closeModal(e)}
          className="active"
          name="acceptErrorMessage"
        >
          {t('Accept')}
          <span className="icon" />
        </SecondaryButton>
      );
    };
    return (
      <>
        <Beforeunload onBeforeunload={() => 'You\'ll lose your data!'} />
        <Modal
          isOpen={modalUSAIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={this.closeModal} />
          <ModalBody className="justify-content-center">
            <ModalTitle>{t('SwitchingToPickup')}</ModalTitle>
            <p>{errorMessage}</p>
            <p>{t('USA No Bill Message')}</p>
            <ButtonDiv className="row">
              <div className="col-sm-12">
                <SecondaryButton
                  onClick={(e) => this.closeModal(e)}
                  className="active"
                  name="pickupUSAMessage"
                >
                  {t('Continue')}
                  <span className="icon" />
                </SecondaryButton>
              </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={ModalBoxStyle}
          contentLabel="Modal"
        >
          <Close onClick={this.closeModal} />
          <ModalBody className="justify-content-center">
            <ModalTitle>{modalTitle()}</ModalTitle>
            <p><strong>{errorMessage}</strong></p>
            <p>{isManageContactPermission || embargoErrorMessage ? t('') : t('PleaseContactCustomerService')}</p>
            <p>{errorMessage && cancelPickup ? LTLCustomerNumber : t('')}</p>
            <p>{errorMessage && cancelPickup ? CommerceCustomerNumber : t('')}</p>
            <ButtonDiv className="row">
              <div className="col-sm-12">
                {getSecondaryButton()} </div>
            </ButtonDiv>
          </ModalBody>
        </Modal>
        <FormProgressBar current={currentStep} steps={this.steps} />
        {this.state.addressPickerModalOpen && <AddressPickerModal
          onClose={this.closeAddressModal}
          handleSubmit={this.handleAddressPickSubmit}
          Options={this.state.AddressOptions}
        />}
        <SignupBanner loggedIn={isAuthenticated} />
        <FormContainer>
          {submitSucceeded ? (
            <>
              <LastActions className="float-right " $msButtonWidth="33.25Rem">
                <Column>
                  {formData && formData.pickupDetails && formData.pickupDetails.createBillOfLading && <Row>
                    <Column>
                      <PrimaryButton
                        style={{
                          margin: '0px',
                          width: '33.25Rem'
                        }}
                        className="active"
                        onClick={() => this.openBOL()}>
                        {t('DownloadPickupDocuments')}
                      </PrimaryButton>
                    </Column>
                  </Row>}
                  <Row>
                    <Column>
                      <MDBDropdown className="float-right">
                        <MDBDropdownToggle>
                          <Row>
                            <Column $tablet={10} $mobile={10}>
                              {t('MoreOptions')}
                            </Column>
                            <span className="icon icon-more-options" />
                          </Row>
                        </MDBDropdownToggle>
                        <StyledMDBDropdownMenu className="text-white bg-dark" basic>
                          <DropdownItem onClick={() => this.cancelFn()}>
                            <DropdownItemIcon iconSrc={CancelWhite} />
                            {t('Cancel')}
                          </DropdownItem>
                        </StyledMDBDropdownMenu>
                      </MDBDropdown>
                    </Column>
                  </Row>
                </Column>
              </LastActions>
              <Step6
                formData={formData}
                quoteData={this.state.data}
                parentFields={fields}
                openBOL={this.openBOL}
                authenticated={isAuthenticated}
              />
            </>
          ) : (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              {this.state.currentStep === 4 && valid && (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} ${this.state.currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon
                      className="icon-left"
                      src={ArrowLeftOrange}
                      alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton type="submit" className="submit float-right">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('Finalize')}
                  </PrimaryButton>
                </ButtonGroup>
              )}
              <Step1
                step={currentStep}
                formData={formData}
                dispatch={dispatch}
                loggedIn={isAuthenticated}
                accountNumbers={accountNumbers}
                parentFields={fields}
                billingAccounts={billingAccounts}
                fromContactId={fromContactId}
                toContactId={toContactId}
                addressFieldsRef={this.addressFieldsRef}
                openContactPermissionModal={this.openContactPermissionModal}
              />
              <Step2
                formData={formData}
                step={currentStep}
                valid={valid}
                dispatch={dispatch}
                accessorialsList={accList}
                parentFields={fields}
                error={formSyncErrors}
              />
              <Step3
                accessorialsList={accList}
                formData={formData}
                step={currentStep}
                dispatch={dispatch}
                parentFields={fields}
                formName={formName}
                prevStep={this.prevStep}
                nextStep={this.nextStep}
              />
              <Step4
                step={currentStep}
                formData={formData}
                dispatch={dispatch}
                parentFields={fields}
                apiReadyTime={apiReadyTime}
              />
              <Step5
                formData={formData}
                step={currentStep}
                editStepOne={this.editStepOne}
                editStepTwo={this.editStepTwo}
                editStepThree={this.editStepThree}
                editStepFour={this.editStepFour}
                parentFields={fields}
              />
              {this.state.currentStep === 4 && valid ? (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} ${this.state.currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton type="submit" className="submit">
                    <Icon className="icon" src={ArrowRightWhite} alt="submit" />
                    {t('Finalize')}
                  </PrimaryButton>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <SecondaryButton
                    type="button"
                    onClick={this.prevStep}
                    className={`${valid ? 'active' : ''} ${this.state.currentStep === 0 ? 'hidden' : ''}`}>
                    <Icon className="icon-left" src={ArrowLeftOrange} alt="back" />
                    {t('Back')}
                  </SecondaryButton>
                  <PrimaryButton
                    type="button"
                    onClick={this.nextStep}
                    className={`${valid && !(currentStep === 1 && !listCompleted(formData.measurementList)) ? 'active' : ''}`}>
                    <Icon className="icon" src={ArrowRightWhite} alt="next" />
                    {t('Next')}
                  </PrimaryButton>
                </ButtonGroup>
              )}
            </form>
          )}
          <QuestionsBlock />
        </FormContainer>
      </>
    );
  }
}

export const addressValidate = (values, dispatch, props, fieldName) => {
  const {
    shipperAddress,
    consigneeAddress,
    division
  } = values;
  const error = { ...props.asyncErrors };
  if (fieldName && fieldName.includes('Nickname')) {
    const addressFieldName = `${fieldName.split('Nickname')[0]}Address`;
    props.touch(addressFieldName); // turn on error messages
    return props.asyncValidate(values, dispatch, props, addressFieldName); // evaluate whether the address field will fail or not
  }
  if (shipperAddress && consigneeAddress && shipperAddress === consigneeAddress) {
    return new Promise(() => {
      const throwError = {
        ...props.asyncErrors,
        consigneeAddress: t('NoSameAddress'),
        shipperAddress: t('NoSameAddress')
      };

      throw throwError;
    });
  }
  if (division === Divisions.Sameday.name) {
    if (shipperAddress && !isCanadianAddress(shipperAddress)) {
      return new Promise(() => {
        const throwError = {
          ...props.asyncErrors,
          shipperAddress: t('NoServiceOutsideCanada')
        };

        throw throwError;
      });
    }
    if (consigneeAddress && !isCanadianAddress(consigneeAddress)) {
      return new Promise(() => {
        const throwError = {
          ...props.asyncErrors,
          consigneeAddress: t('NoServiceOutsideCanada')
        };

        throw throwError;
      });
    }
  }
  if (shipperAddress && !isCanadianAddress(shipperAddress) && consigneeAddress && !isCanadianAddress(consigneeAddress)) {
    return new Promise(() => {
      const throwError = {
        ...props.asyncErrors,
        shipperAddress: t('OneAddressMustBeCanadian'),
        consigneeAddress: t('OneAddressMustBeCanadian')
      };

      throw throwError;
    });
  }

  if (props.asyncErrors && (props.asyncErrors.shipperAddress === t('NoSameAddress')
    || props.asyncErrors.shipperAddress === t('OneAddressMustBeCanadian'))) {
    error.shipperAddress = undefined;
  }
  if (props.asyncErrors && (props.asyncErrors.consigneeAddress === t('NoSameAddress')
    || props.asyncErrors.consigneeAddress === t('OneAddressMustBeCanadian'))) {
    error.consigneeAddress = undefined;
  }
  error[fieldName] = undefined;
  const hasError = Object.keys(error).reduce((accum, curr) => (error[curr] ? true : accum), false);
  return hasError ? new Promise(() => { throw error; }) : Promise.resolve();
};

CreatePickupForm = reduxForm({
  form: formName,
  initialValues,
  validate: measurementValidate,
  asyncValidate: addressValidate,
  asyncBlurFields: ['consigneeAddress', 'shipperAddress', 'consigneeNickname', 'shipperNickname']
})(CreatePickupForm);

const mstp = (state) => ({
  formData: getFormValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  isAuthenticated: state.profile.isAuthenticated,
  accountNumbers: state.profile.accountNumbers,
  billingAccounts: state.profile.billingAccounts,
  division: state.profile.division,
  userId: state.profile.userId,
  userEmail: state.profile.email,
});

const mdtp = () => ({});

export default connect(mstp, mdtp)(CreatePickupForm);
